.badge-stat {
    width: 100%;
    min-height: 140px;
    background-color: $card-background-color;
    box-sizing: border-box;
    padding: 0 calc($padding + 1rem);
    margin-bottom: $margin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius;
    transition: $transition;
    animation: $fade-in-animation;
    cursor: pointer;

    &:hover {
        box-shadow: $box-shadow;
        // padding: 2rem;
    }

    .left-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .title {
            text-transform: capitalize;
            font-weight: $font-weight-regular;
            font-size: $title-font-size;
            color: $text-color;
            animation: $pop-animation;
        }

        .count {
            font-size: 1.5em;
            font-weight: $font-weight-semibold;
            color: $label-color;
            animation: $pop-animation;
        }
    }

    .right-section {

        .icon {
            height:$header-height;
            width:$header-height;
            background-color: $primary-color;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: $transition;
            animation: $pop-animation;

            i {
                font-size: 2em;
                color: $light-color;

            }
        }

    }
}