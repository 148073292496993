.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $border-radius;
    background-color: $card-background-color;
    padding: $card-padding 0px;
    color: $text-color;
    width: 100%;
    animation: $fade-in-animation;
    margin-bottom: calc($margin);
    max-height: calc(100vh - $header-height - ($margin * 2));

    ::-webkit-scrollbar {
        display: none;
    }

    &:hover {
        ::-webkit-scrollbar {
            display: block;
            animation: $fade-in-animation;
        }
    }

    &.list {
        max-height: calc(100vh - ($header-height * 2) - ($margin * 2) - 20px);

        .card-content {
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: auto;
            // animation: $fade-in-animation;;
            animation: $fade-in-animation;
            padding: 0 $card-padding;
        }

        @media #{$large-and-up} {
            .card-content {
                position: relative;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                overflow-x: auto;
                animation: $fade-in-animation;
                padding: 0 $card-padding;
                margin-top: $card-padding;
            }

        }

    }

    .card-title {
        color: $label-color;
        font-size: $title-font-size;
        font-weight: $font-weight-semibold;
        text-transform: capitalize;
        padding: 0 $card-padding 1rem $card-padding;
    }

    .card-content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 $card-padding 0 $card-padding;
        overflow-y: auto;
        overflow-x: auto;
        animation: $fade-in-animation;
    }


    .card-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 $card-padding;
        width: 100% !important;

        .left-section {
            input {
                margin: $no-margin;
            }
        }
    }


    @media screen and (max-width: $medium-screen) {

        &.list {
            margin: $no-margin;
            padding: $no-padding;
            // max-height: calc(100vh - ($header-height * 2) - 30px);
        }

        .card-filter {
            width: 100vw;
            display: inline-flex;
            flex-direction: column;

            .left-section {
                width: 100%;
                padding: $padding $padding 0 $padding;
            }

            .right-section {
                padding: .5rem 0;
            }
        }
    }
}