.breadcrumb {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 996;
    background-color: $background-color;
    width: calc(100% - $sidebar-width);
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: $transition;

    .section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: $transition;
        padding: 15px;

        i {
            font-size: $icon-font-size;
            color: $primary-color;
        }

        .title {
            margin-left: $space;
            font-size: $font-size;
            font-weight: $font-weight-semibold;
            color: $label-color;
            text-transform: capitalize;
        }
    }

    .right-section {
        i {
            font-size: $icon-font-size !important;
        }

        .title {
            margin-left: 0rem;
            font-size: $font-size;
            font-weight: $font-weight-semibold;
            color: $label-color;
            // text-transform: lowercase;
        }
    }
}